Lyte.Component.register("crux-create-button", {
_template:"<template tag-name=\"crux-create-button\"> <template items=\"{{cxPropButtons}}\" item=\"btn\" index=\"index\" is=\"for\"> <lyte-button lt-prop-disabled=\"{{expHandlers(moduleCurntInstObj.disableFormbuttons,'?:',moduleCurntInstObj.disableFormbuttons,btn.butnDisabled)}}\" data-zcqa=\"{{btn.name}}\" lt-prop-name=\"{{btn.name}}\" onclick=\"{{action('createFormSubmit',btn,this,event)}}\" lt-prop-appearance=\"{{expHandlers(btn.appearance,'?:',btn.appearance,'default')}}\" lt-prop-class=\"{{expHandlers(btn.class,'?:',btn.class,'basicbutton')}}\" lt-prop-id=\"{{btn.id}}\" id=\"{{btn.id}}{{module}}\"> <template is=\"registerYield\" yield-name=\"text\">{{btn.label}} </template> </lyte-button> </template> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"for","position":[1],"dynamicNodes":[{"type":"attr","position":[1]},{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"text","position":[0]}]},{"type":"componentDynamic","position":[1]}]}],
_observedAttributes :["cxPropButtons","cxPropLayoutComponentData","instanceObjKey","moduleCurntInstObj","cxPropModuleData"],
	data: function () {
		return {
			cxPropButtons: Lyte.attr('array', { 'default': [] }), //no i18n
			cxPropLayoutComponentData: Lyte.attr('object', { 'default': {} }),//no i18n
			instanceObjKey: Lyte.attr("string", { "default": "" }),//no i18n
			moduleCurntInstObj: Lyte.attr("object", { "default": {} }), //no i18n
			cxPropModuleData: Lyte.attr('object', { 'default': {} })//no i18n
		}
	},
	init: function () {
		var layoutComponentData = this.data.cxPropLayoutComponentData;
		var defaultButtons = [
			{
				"butnDisabled": false,
				"name": "cancel",//no i18n
				"id": "cx_create_cancelbutn",//no i18n
				"label": _cruxUtils.getI18n("crm.button.cancel")//no i18n
			},
			{
				"butnDisabled": false,
				"name": "save",//no i18n
				"id": "cx_create_savebutn",//no i18n
				'label': _cruxUtils.getI18n("crm.button.save"),//no i18n
				'appearance': 'primary', 'class': 'primarybtn'//no i18n
			}
		];
		if (layoutComponentData.isQuickCreate) {
			Lyte.arrayUtils(defaultButtons, "insertAt", 1, {
				"butnDisabled": false,
				"name": "saveandassociate",//no i18n
				"id": "cx_create_saveandassociatebutn",//no i18n
				'appearance': 'primary', 'class': 'primarybtn',//no i18n
				"label": _cruxUtils.getI18n("crm.button.save.and.associate")//no i18n
			});
		} else {
			Lyte.arrayUtils(defaultButtons, "insertAt", 1, {
				"butnDisabled": false,
				"name": "saveAndNew",//no i18n
				"id": "cx_create_saveandnewbutn",//no i18n
				"label": _cruxUtils.getI18n("crm.button.save&new")//no i18n
			});
		}
		let defaultButtonsOriginal = Lyte.deepCopyObject(defaultButtons), buttonProperties = ['appearance', 'butnDisabled', 'name', 'id', 'label'];//no i18n
		if (layoutComponentData.buttonData && layoutComponentData.buttonData.length) {
			try {
				layoutComponentData.buttonData.forEach(formButton => {
					let currentButtons = defaultButtons.map(butn => { return butn.name; }),
						existingIndex = currentButtons.indexOf(formButton.name);
					if (existingIndex !== -1) {
						let finalButtonObj = Object.assign({}, formButton),
							existingButtonInfo = defaultButtons[existingIndex];
						buttonProperties.forEach(key => {
							if (!formButton.hasOwnProperty(key) && existingButtonInfo.hasOwnProperty(key)) {
								finalButtonObj[key] = existingButtonInfo[key];
							}
						});
						if (finalButtonObj.hasOwnProperty('position')) {
							let indexVal = Number(finalButtonObj.position),
								isValidIndex = defaultButtons.length >= indexVal;
							if (isValidIndex) {
								Lyte.arrayUtils(defaultButtons, "removeAt", existingIndex, 1);
								Lyte.arrayUtils(defaultButtons, "insertAt", (--indexVal), finalButtonObj);
							}
						} else {
							Lyte.arrayUtils(defaultButtons, "replaceAt", existingIndex, finalButtonObj);
						}
					} else {
						if (formButton.hasOwnProperty('position')) {
							let indexVal = Number(formButton.position),
								isValidIndex = defaultButtons.length >= indexVal;
							if (isValidIndex) {
								Lyte.arrayUtils(defaultButtons, "insertAt", (--indexVal), formButton);
							}
						} else {
							defaultButtons.push(formButton);
						}
					}
				});
			} catch (exe) {
				defaultButtons = defaultButtonsOriginal;
			}
		}
		this.setData('cxPropButtons', defaultButtons);//no i18n
	},
	actions: {
		// Functions for event handling
		createFormSubmit: async function (buttonObj, lyteButtonNode, event) {
			var cbObject = { currentButtonObj: buttonObj, currentButtonNode: lyteButtonNode, eventDetails: event },
				layoutComponentData = this.data.cxPropLayoutComponentData;
			if (buttonObj.name !== 'cancel') {
				var onBeforeSaveClickCBResponse = this.invokeCruxFormCallBacks({ callbackEventName: 'onFormSaveClick', onFormSaveClick: cbObject });//no i18n
				onBeforeSaveClickCBResponse.then(function (promiseResponse) {
					let skippedExecution = true;
					if (promiseResponse === false) {
						return { skippedExecution };
					} else {
						this.validateAndSaveForm({ callBackDataObject: promiseResponse, currentButtonObj: buttonObj, currentButtonNode: lyteButtonNode, event: event });
					}
				}.bind(this));
			} else {
				let cbValue = await this.invokeCruxFormCallBacks({ callbackEventName: 'onFormCancel', onFormCancel: cbObject });//no i18n
				if (cbValue === false) {
					return;
				}
				this.destroyComponent(layoutComponentData);
			}
		}
	},
	methods: {
		// Functions which can be used as callback in the component.
	}
}, {
	mixins: [
		"crux-create-base-mixin",
		"crux-entity-common-utils",
		"crux-element-validation",
		"crux-create-validators-mixin",
		"crux-common-rules-utils",
		"crux-create-rules-mixin",
		"crux-create-requesthandler-mixin",
		"crux-entity-date-time-mixin"
	]
});